var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{class:[
    'r-select-option',
    _vm.focused ? 'focus' : '',
    _vm.disabled ? 'disabled' : '',
    _vm.multiple ? 'multiple' : '',
    _setup.isSelected ? 'active' : '',
    _vm.hasNoTextData ? 'has-no-text-data' : ''
  ],attrs:{"id":_vm.id},on:{"click":function($event){$event.stopPropagation();return _setup.$emit('click', $event)}}},[_vm._t("default"),(!_setup.slots.default && _vm.html)?_c(_setup.RenderOption,{attrs:{"dom":_setup.slot}}):_vm._e(),(!_vm.html)?_c('div',{staticClass:"r-select-option__item-wrapper",class:{ withIcon: !_vm.multiple && !!_vm.icon }},[(_vm.multiple)?_c('r-checkbox',{attrs:{"label":_vm.title,"checked":_setup.isSelected,"white-space":_vm.mode === 'wrap' ? 'unset' : 'nowrap'},on:{"click":function($event){return _setup.$emit('click', $event)}}}):_vm._e(),(!_vm.multiple && _vm.icon)?_c('r-icon',{attrs:{"name":_vm.icon.name,"color-type":_vm.icon.colorType,"title":_vm.title}}):_vm._e(),(!_vm.multiple)?_c('r-text',{attrs:{"title":_vm.title}},[_vm._v(" "+_vm._s(_vm.title !== undefined && _vm.title !== null ? _vm.title : '—')+" ")]):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }